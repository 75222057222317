import React from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Education = (props) => {
    const id = "education";
    const content = props.content;
    const diplomas = Object.entries(content.diplomas);

    return (
        <section className="page-section" id={id}>
            <Container>
                <h2 className="text-center mt-0">{content.title}</h2>
                <hr className="divider my-4" />
                <Row>
                    {
                        diplomas.map((diploma, index) => <DiplomaDisplay key={index} diploma={diploma[1]} />)
                    }
                </Row>
            </Container>
        </section>
    )
}

const DiplomaDisplay = (props) => {
    var diploma = props.diploma;

    return (
        <Col lg="4" md="6" className="text-center d-md-flex align-items-stretch mx-auto" data-aos="fade-right">
            <Card className="mb-4 w-100">
                <CardHeader className="pt-3">
                    <FontAwesomeIcon icon={["fas", diploma.logo]} size="2x" className="text-primary mb-3" />
                    <h4 className="h4">{diploma.title}</h4>
                </CardHeader>
                <CardBody className="p-3">
                    <h5 className="h5 text-primary mb-4">{diploma.diploma_title}</h5>
                    <h6 className="h6 mb-3">{diploma.school}</h6>
                    <hr className="hr" />
                    <ul className="text-muted text-left">
                        {
                            diploma.main_subjects.map((subject, index) => <li key={index}>{subject}</li>)
                        }
                    </ul>
                </CardBody>
            </Card>
        </Col>
    )
}
