import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Col, Row, Button } from 'reactstrap';
import { Avatar } from "../common/Avatar";

export const About = (props) => {
    const id = "about";
    const content = props.content;
    const items = Object.entries(content.items);

    return (
        <section className="page-section bg-primary" id={id}>
            <Container >
                <Row className="align-items-center">
                    <Col lg="3" className="align-self-center text-center mb-4  mt-lg-5">
                        <Avatar />
                        <p>
                            <Button color="primary" size="lg" className="mt-4 js-scroll-trigger" href="#contact">{content.contact_me}</Button>
                        </p>
                    </Col>
                    <Col lg="9" className="about text-justify" >
                        <div>
                            <h2 className="text-center mt-0" >{content.title}</h2>
                            <hr className="divider my-4" />
                        </div>

                        <Row>
                            {
                                items.map((item, index) => <ItemDisplay key={index} item={item[1]} />)
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

const ItemDisplay = (props) => {
    const item = props.item;

    return (
        <Col md="6" className="my-3" data-aos="fade-up">
            <div>
                <h3 className="h4">
                    <FontAwesomeIcon icon={["fas", item.logo]} className="text-primary mr-3" />{item.title}
                </h3>
            </div>
            <div style={{ "fontSize": "15px" }}>{item.content}</div>
        </Col>
    )
}