import React, { useState } from 'react';
import {
    Form, FormGroup, Input, FormFeedback, Card, CardBody, CardTitle, CardHeader, Col, Row, InputGroup,
    InputGroupAddon, InputGroupText, Button, Alert
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emailjs from 'emailjs-com';


export const ContactForm = (props) => {
    const [formValid, setFormValid] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const info = props.Info;
    const alert = info.alert_messages;
    const usr = 'user_nkQcVq8Ngs2zuRRZefUYp';

    const schema = yup.object().shape({
        name: yup.string(`${info.name.title} ${alert.string}`)
            .required(`${info.name.title} ${alert.required}`)
            .min(4, `${info.name.title} ${alert.min.replace("{value}", "4")}`),
        email: yup.string(`${info.email.title} ${alert.string}`)
            .required(`${info.email.title} ${alert.required}`)
            .email(`${info.email.title} ${alert.invalid}`),
        subject: yup.string(`${info.subject.title} ${alert.string}`),
        message: yup.string(`${info.message.title} ${alert.string}`)
            .required(`${info.message.title} ${alert.required}`)
            .min(10, `${info.message.title} ${alert.min.replace("{value}", "10")}`),
    });

    emailjs.init(usr);

    const integration = {
        service: 'service_k1twjkf',
        template: 'template_0lsh0cr'
    };

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data, e) => {
        emailjs.send(integration.service, integration.template, data)
            .then(() => {
                alertDisplayEmailSent(true);
                reset({});
                e.target.reset();
            }, () => {
                alertDisplayEmailSent(false);
            });
    }

    const alertDisplayEmailSent = (EmailValid) => {
        setEmailSent(EmailValid);
        setFormValid(true);
        setTimeout(() => {
            setEmailSent(false);
            setFormValid(false);
        }, 6000);
    }

    return (
        <Card className="h-100">
            <CardHeader>
                <CardTitle className="h4 text-center mt-3">{info.sub_title}</CardTitle>
            </CardHeader>
            <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col sm="12" md="6" lg="12">
                            <InputGroup className="mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText id="name-addon">
                                        <FontAwesomeIcon icon={["fas", "user-tie"]} className="text-primary" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input {...register("name")}
                                    className={`${errors.name ? 'is-invalid' : ''}`}
                                    placeholder={info.name.placeholder}
                                    name="name"
                                    aria-describedby="name-addon"
                                    autoComplete="off"
                                />
                                <FormFeedback>{errors.name?.message}</FormFeedback>
                            </InputGroup>
                        </Col>
                        <Col sm="12" md="6" lg="12">
                            <InputGroup className="mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText id="email-addon">
                                        <FontAwesomeIcon icon={["fas", "envelope"]} className="text-primary" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input {...register("email")}
                                    type="email"
                                    className={`${errors.email ? 'is-invalid' : ''}`}
                                    placeholder={info.email.placeholder}
                                    name="email"
                                    aria-describedby="email-addon"
                                />
                                <FormFeedback>{errors.email?.message}</FormFeedback>
                            </InputGroup>
                        </Col>
                    </Row>
                    <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText id="subject-addon">
                                <FontAwesomeIcon icon={["fas", "heading"]} className="text-primary" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input {...register("subject")}
                            className={`${errors.subject ? 'is-invalid' : ''}`}
                            placeholder={info.subject.placeholder}
                            name="subject"
                            aria-describedby="subject-addon"
                            autoComplete="off"
                        />
                        <FormFeedback>{errors.subject?.message}</FormFeedback>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Input {...register("message")}
                            className={`${errors.message ? 'is-invalid' : ''}`}
                            placeholder={info.message.placeholder}
                            name="message"
                            type="textarea"
                            rows={5}
                        />
                        <FormFeedback>{errors.message?.message}</FormFeedback>
                    </InputGroup>
                    <FormGroup className="text-center">
                        <Button type="submit" color="primary">{info.submit}</Button>
                    </FormGroup>
                    <Alert color={emailSent ? "success" : "danger"} isOpen={formValid}>
                        {emailSent ? alert.submited : alert.sending_error}
                    </Alert>
                </Form>
            </CardBody>
        </Card>
    )
}