import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import { TagCloud } from '../common/TagCloud';

export const Experiences = (props) => {
    const id = "experiences";
    const content = props.content;
    const jobs = Object.entries(content.jobs);

    return (
        <section className="page-section  parallax" id={id}>
            <Container>
                <Row>
                    <Col>
                        <h2 className="text-center mt-0 text-light">{content.title}</h2>
                        <hr className="divider my-4 text-primary" />
                        <div className="main-timeline p-4">
                            {
                                jobs.map((job, index) => <JobDisplay key={index} job={job[1]} />)
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

const JobDisplay = (props) => {
    var job = props.job;

    return (
        <div className="timeline" data-aos="fade-up">
            <span className="timeline-icon" />
            <span className="year">{job.date}</span>

            <div className="timeline-content">
                <h3 className="title text-center">{job.title}</h3>
                <ToggleExperience job={job} />
            </div>
        </div>
    )
}


const ToggleExperience = (props) => {
    var job = props.job;
    let isATagCloud = job.type_list === "tagcloud";

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="text-center">
            <Button className="toggle-btn m-2" onClick={toggle}>
                {isOpen
                    ? <FontAwesomeIcon icon={["fas", "angle-double-up"]} />
                    : <FontAwesomeIcon icon={["fas", "angle-double-down"]} />
                }
            </Button>
            <Collapse isOpen={isOpen}>
                <div>
                    <h4 className="company">{job.company_name}</h4>
                    <div className="description">
                        <p>{job.description}</p>
                        {(() => {
                            if (isATagCloud) {
                                return <TagCloud List={job.list} />;
                            } else {
                                return (
                                    <ul>
                                        {job.list.map((item, index) => <li className="m-1" key={index}>{item}</li>)}
                                    </ul>
                                )
                            }
                        })()}
                    </div>
                </div>
            </Collapse>
        </div >
    );
}