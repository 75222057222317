import React from 'react';
import { Card, CardBody, CardFooter } from "reactstrap";
import { SocialNetworks } from '../common/SocialNetworks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from '../common/Avatar';

export const ContactCard = (props) => {
    const info = props.Info;

    return (
        <Card className="text-center">
            <div className="card-up" />
            <CardBody>
                <Avatar />
                <h3 className="h4">Emilien Boinet</h3>
                <h5 className="h5 text-primary mb-4">{info.job_title}</h5>
                <figure>
                    <blockquote className="m-0">
                        <p className="text-left m-0">
                            <FontAwesomeIcon icon={["fas", "quote-left"]} className="mr-2" />
                            {info.quote}
                            <FontAwesomeIcon icon={["fas", "quote-right"]} className="ml-2" />
                        </p>
                    </blockquote>
                    <figcaption className="text-right font-italic">{info.author}</figcaption>
                </figure>
                <div className="contact-info d-block">
                    <a href="mailto:emilien.boinet@gmail.com">
                        <FontAwesomeIcon icon={["fas", "envelope"]} className="mr-2" />emilien.boinet@gmail.com
                    </a>
                </div>
            </CardBody>
            <CardFooter className="p-3">
                <SocialNetworks />
            </CardFooter>
        </Card>
    )
}