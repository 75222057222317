import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
//import HttpApi from 'i18next-http-backend';

const languagesData = {
   en: {
      root: {
         navmenu: {
            home: "Home",
            about: "About",
            skills: "Skills",
            experiences: "Experiences",
            education: "Education",
            projects: "Projects",
            contact: "Contact",
         },
         home: {
            greeting: "Hello, I am",
            job_title: "Junior .NET Developer",
         },
         about: {
            title: "About Me",
            contact_me: "Contact Me!",
            items: {
               introduction: {
                  title: "Overview",
                  content:
                     "Based in France, I am currently looking for a Junior .Net Developer position.",
                  logo: "address-card",
               },
               environnement: {
                  title: "Skills",
                  content:
                     "Fond of new technologies and with a master's degree in computer science, it is with the Microsoft .Net environment and the C# language that I can best express myself.",
                  logo: "code",
               },
               experiences: {
                  title: "Experiences",
                  content:
                     "Throughout my professional experience, I have been involved in the development and maintenance of applications, in requirement analysis and in project management.",
                  logo: "user-tie",
               },
               hobbies: {
                  title: "Hobbies",
                  content:
                     "When I am not coding, you will find me travelling, reading, running or playing basketball. These activities have helped me improve my teamwork, organisation and communication skills.",
                  logo: "compass",
               },
            },
         },
         skills: {
            title: "My Skills",
            main_skills: "Technical & Professional Skills",
            certificate: "Certificates",
            types: {
               backend: {
                  title: "Back-End",
                  skills: [
                     "C#",
                     "ASP.NET Core",
                     "MVC",
                     "Web Api",
                     "Entity Framework",
                     "Python",
                     "VB.NET",
                  ],
                  logo: "file-code",
               },
               frontend: {
                  title: "Front-End",
                  skills: [
                     "HTML5",
                     "CSS3",
                     "Bootstrap",
                     "Reactstrap",
                     "JavaScript",
                     "React",
                  ],
                  logo: "laptop-code",
               },
               others: {
                  title: "Others",
                  skills: [
                     "SQL",
                     "GIT",
                     "GitHub",
                     "Visual Studio",
                     "SQL Server",
                     "Agile",
                     "Kanban",
                     "Project Management",
                  ],
                  logo: "globe",
               },
            },
            certificates: {
               fce: {
                  abb: "FCE",
                  title: "FCE - First Certificate in English",
                  level: "177 / 200 ~ C1",
                  location:
                     "Shafston International College: Brisbane English School",
                  date: "September 2020",
               },
               toeic: {
                  abb: "TOEIC",
                  title: "Test of English for International Communication",
                  level: "815 / 990 - B2",
                  location: "CNAM: Amiens, France",
                  date: "June 2015",
               },
            },
         },
         experiences: {
            title: "My Experiences",
            jobs: {
               ageco_web_developer: {
                  title: "Web Developer",
                  date: "Aug 2019 - Jan 2020",
                  company_name: "Ageco Agencement",
                  description:
                     "Web Applications development & Management and deployment of a 3CX Telephone system.",
                  list: [
                     "Python",
                     "Flask",
                     "MySQL",
                     "HTML",
                     "CSS",
                     "JavaScript",
                     "JSON",
                     "Boostrap",
                  ],
                  type_list: "tagcloud",
               },
               wn_information_systems_manager: {
                  title: "Information Systems Manager",
                  date: "Sept 2018 - Aug 2019",
                  company_name: "WN",
                  description:
                     "Project management, deployment and maintenance of a new IT infrastructure (70 workstations, 10 Windows 2016 servers).",
                  list: [
                     "Implementation and configuration of business software",
                     "Development of in-house applications",
                     "Active Directory and GPO management",
                     "Switch configuration (Aruba 2930)",
                     "ERP (CEGID PMI), Payroll & Accounting (SAGE 100)",
                     "Management of two IT apprentices",
                     "IT Support",
                  ],
                  type_list: "",
               },
               whirlpool_apprentice_dot_net: {
                  title: "Apprentice .NET Software Developer",
                  date: "Sept 2015 - Sept 2018",
                  company_name: "Whirlpool",
                  description:
                     "Development and maintenance of .NET solutions for internal use. Business process automation via in-house application development.",
                  list: [
                     "C#",
                     "VB.net",
                     "SQL",
                     "HTML",
                     "CSS",
                     "SQL Server",
                     "ASP.Net",
                     "Agile",
                     "Project Management",
                  ],
                  type_list: "tagcloud",
               },
               hotellier_intern_web_developer: {
                  title: "Intern Web Developer",
                  date: "May - June 2014",
                  company_name: "Groupe L'Hotellier IKOS",
                  description:
                     "Web application development to automatically inventory the IT assets.",
                  list: [
                     "PHP",
                     "MySQL",
                     "Perl",
                     "HTML",
                     "CSS",
                     "JavaScript",
                     "Jquery",
                     "XML",
                  ],
                  type_list: "tagcloud",
               },
            },
         },
         education: {
            title: "My Education",
            diplomas: {
               master: {
                  title: "Master’s Degree in Engineering",
                  diploma_title: "Master's Degree in Computer Science",
                  school: "Conservatoire National Des Arts Et Métiers (CNAM)",
                  main_subjects: [
                     "Programming",
                     "Project Management",
                     "Communication",
                     "Networks",
                     "Information System",
                     "English",
                  ],
                  logo: "user-graduate",
               },
               bts: {
                  title: "BTEC Higher National Diploma",
                  diploma_title:
                     "BTEC Higher National Qualifications in Computing",
                  school: "La Providence, Amiens, France",
                  main_subjects: [
                     "Industrial data processing",
                     "Applied Physics",
                     "Mathematics",
                  ],
                  logo: "university",
               },
               high_school: {
                  title: "French High School Diploma",
                  diploma_title: "Higher School Certificate",
                  school: "La Hotoie, Amiens, France",
                  main_subjects: [
                     "Mathematics",
                     "Physics - Chemistry",
                     "Life Sciences",
                  ],
                  logo: "book-open",
               },
            },
         },
         projects: {
            title: "My Projects",
            projects: {
               visitor_management: {
                  title: "Visitor Management",
                  description:
                     "Application to manage the check in and check out of visitors in a company in order to control attendance and improve on-site security.",
                  github_link: "https://github.com/Mimi-Git/visitor-management",
                  technologies_used: [
                     "NET Core 5.0",
                     "REACT",
                     "API",
                     "Entity Framework",
                     "DTO",
                     "Repository Pattern",
                     "HTML",
                     "CSS",
                  ],
                  image: "assets/images/projects/visitor_management",
                  live_demo: "https://visitor-management.netlify.app/",
                  status: "In progress",
               },
               inventory_management: {
                  title: "Inventory Management",
                  description:
                     "Application that provides a basic and simple inventory of personal items.",
                  github_link: "",
                  technologies_used: [
                     "NET Core",
                     "Entity Framework",
                     "React",
                     "HTML",
                     "CSS",
                     "API",
                  ],
                  image: "assets/images/projects/inventory_management",
                  live_demo: "https://www.emilien-boinet.fr",
                  status: "To-Do",
               },
               holiday_planner: {
                  title: "Holiday Planner",
                  description:
                     "The Holiday Planner is an application that allows you to create a personalised day-by-day itinerary for holidays.",
                  github_link: "",
                  technologies_used: [
                     "NET Core",
                     "Angular",
                     "HTML",
                     "CSS",
                     "API",
                     "Entity Framework",
                  ],
                  image: "assets/images/projects/holiday_planner",
                  live_demo: "https://www.emilien-boinet.fr",
                  status: "To-Do",
               },
            },
         },
         contact: {
            title: "Contact",
            sub_title: "Contact Me",
            job_title: "Junior .NET Developer",
            quote: "Programming isn't about what you know; it's about what you can figure out.",
            author: "Chris Pine",
            name: {
               placeholder: "Name *",
               title: "The name",
            },
            email: {
               placeholder: "Email *",
               title: "The email",
            },
            subject: {
               placeholder: "Subject",
               title: "The subject",
            },
            message: {
               placeholder: "Message *",
               title: "The message",
            },
            submit: "Submit",
            alert_messages: {
               string: "must be a string",
               required: "is required",
               min: "must be at least {value} characters",
               invalid: "is invalid",
               submited: "The email has been sent !",
               sending_error:
                  "Oops, An error has occurred, please send me a message at emilien.boinet@gmail.com.",
            },
         },
         social_networks: {
            cv: {
               href: "./assets/documents/CV_Emilien_BOINET_En.pdf",
               icon: ["fas", "file-alt"],
            },
            github: {
               href: "https://github.com/Mimi-Git",
               icon: ["fab", "github-square"],
            },
            linkedin: {
               href: "https://fr.linkedin.com/in/emilien-boinet",
               icon: ["fab", "linkedin"],
            },
            email: {
               href: "mailto:emilien.boinet@gmail.com",
               icon: ["fas", "envelope"],
            },
         },
         footer: {
            made: "Website built with ",
         },
      },
   },
   fr: {
      root: {
         navmenu: {
            home: "Accueil",
            about: "À propos",
            skills: "Compétences",
            experiences: "Expériences",
            education: "Formations",
            projects: "Projets",
            contact: "Contact",
         },
         home: {
            greeting: "Bonjour, Je suis",
            job_title: "Développeur Junior .Net",
         },
         about: {
            title: "À propos de moi",
            contact_me: "Contactez-moi !",
            items: {
               introduction: {
                  title: "Aperçu",
                  content:
                     "Habitant en France, je suis actuellement à la recherche d'un poste de Développeur Junior .Net.",
                  logo: "address-card",
               },
               environnement: {
                  title: "Aptitudes",
                  content:
                     "Passionné par les nouvelles technologies et titulaire d'un master en informatique, c'est avec l'environnement Microsoft .Net et le langage C# que j'évolue le mieux.",
                  logo: "code",
               },
               experiences: {
                  title: "Expériences",
                  content:
                     "Lors de mes expériences professionnelles, j'ai participé au développement et à la maintenance d'applications, à l'analyse des besoins mais aussi au management de projets.",
                  logo: "user-tie",
               },
               hobbies: {
                  title: "Centre d’intérêts",
                  content:
                     "Lorsque je ne code pas, vous me trouverez occupé à voyager, lire, courir ou jouer au basket. Ces activités m'ont permis d'améliorer mon travail en équipe, mon sens de l'organisation et ma communication.",
                  logo: "compass",
               },
            },
         },
         skills: {
            title: "Mes Compétences",
            main_skills: "Compétences techniques et professionnelles",
            certificate: "Certificats",
            types: {
               backend: {
                  title: "Back-End",
                  skills: [
                     "C#",
                     "ASP.NET Core",
                     "MVC",
                     "Web Api",
                     "Entity Framework",
                     "Python",
                     "VB.NET",
                  ],
                  logo: "file-code",
               },
               frontend: {
                  title: "Front-End",
                  skills: [
                     "HTML5",
                     "CSS3",
                     "Bootstrap",
                     "Reactstrap",
                     "JavaScript",
                     "React",
                  ],
                  logo: "laptop-code",
               },
               others: {
                  title: "Autres",
                  skills: [
                     "SQL",
                     "GIT",
                     "GitHub",
                     "Visual Studio",
                     "SQL Server",
                     "Agile",
                     "Kanban",
                     "Management de Projet",
                  ],
                  logo: "globe",
               },
            },
            certificates: {
               fce: {
                  abb: "FCE",
                  title: "FCE - First Certificate in English",
                  level: "177 / 200 ~ C1",
                  location:
                     "Shafston International College: Brisbane English School",
                  date: "Septembre 2020",
               },
               toeic: {
                  abb: "TOEIC",
                  title: "Test of English for International Communication",
                  level: "815 / 990 - B2",
                  location: "CNAM: Amiens, France",
                  date: "Juin 2015",
               },
            },
         },
         experiences: {
            title: "Mes Expériences",
            jobs: {
               ageco_web_developer: {
                  title: "Développeur Web",
                  date: "Août 2019 - Jan 2020",
                  company_name: "Ageco Agencement",
                  description:
                     "Développement d'applications Web & Gestion et déploiement du système téléphonique 3CX.",
                  list: [
                     "Python",
                     "Flask",
                     "MySQL",
                     "HTML",
                     "CSS",
                     "JavaScript",
                     "JSON",
                     "Boostrap",
                  ],
                  type_list: "tagcloud",
               },
               wn_information_systems_manager: {
                  title: "Chargé des Systèmes d'information",
                  date: "Sept 2018 - Août 2019",
                  company_name: "WN",
                  description:
                     "Gestion de projets, déploiement et maintenance d'une nouvelle infrastructure informatique (70 postes de travail, 10 serveurs Windows 2016).",
                  list: [
                     "Mise en place et configuration de logiciels metier",
                     "Développement d’applications en interne",
                     "Gestion d'Active Directory et de GPO",
                     "Configuration de switch (Aruba 2930)",
                     "ERP (CEGID PMI), Paie & Comptabilité (SAGE 100)",
                     "Encadrement de deux apprentis IT",
                     "Support informatique",
                  ],
                  type_list: "",
               },
               whirlpool_apprentice_dot_net: {
                  title: "Ingénieur Développeur .NET en apprentissage",
                  date: "Sept 2015 - Sept 2018",
                  company_name: "Whirlpool",
                  description:
                     "Développement et maintenance de solutions .NET sur l'intranet. Automatisation des processus métier par le développement d'applications internes.",
                  list: [
                     "C#",
                     "VB.net",
                     "SQL",
                     "HTML",
                     "CSS",
                     "SQL Server",
                     "ASP.Net",
                     "Agile",
                     "Project Management",
                  ],
                  type_list: "tagcloud",
               },
               hotellier_intern_web_developer: {
                  title: "Développeur Web stagiaire",
                  date: "Mai - Juin 2014",
                  company_name: "Groupe L'Hotellier IKOS",
                  description:
                     "Développement d’une application web permettant d’inventorier de manière automatisée un parc informatique",
                  list: [
                     "PHP",
                     "MySQL",
                     "Perl",
                     "HTML",
                     "CSS",
                     "JavaScript",
                     "Jquery",
                     "XML",
                  ],
                  type_list: "tagcloud",
               },
            },
         },
         education: {
            title: "Mes Formations",
            diplomas: {
               master: {
                  title: "Master en ingénierie",
                  diploma_title: "Master en informatique",
                  school: "Conservatoire National Des Arts Et Métiers (CNAM)",
                  main_subjects: [
                     "Programmation",
                     "Management de projet",
                     "Communication",
                     "Réseaux",
                     "Système d'information",
                     "Anglais",
                  ],
                  logo: "user-graduate",
               },
               bts: {
                  title: "Brevet de Technicien Supérieur",
                  diploma_title:
                     "BTS Informatique et Réseaux pour l'Industrie et les Services Techniques",
                  school: "La Providence, Amiens, France",
                  main_subjects: [
                     "Informatique Industriel",
                     "Physique Appliquée",
                     "Mathématiques",
                  ],
                  logo: "university",
               },
               high_school: {
                  title: "Baccalauréat",
                  diploma_title: "Baccalauréat Scientifique",
                  school: "La Hotoie, Amiens, France",
                  main_subjects: ["Mathématiques", "Physique - Chimie", "SVT"],
                  logo: "book-open",
               },
            },
         },
         projects: {
            title: "Mes Projets",
            projects: {
               visitor_management: {
                  title: "Gestion des Visiteurs",
                  description:
                     "Application permettant de gérer les entrées et sorties des visiteurs d'une entreprise dans le but de contrôler les présences et d'améliorer la sécurité sur le site",
                  github_link: "https://github.com/Mimi-Git/visitor-management",
                  technologies_used: [
                     "NET Core 5.0",
                     "REACT",
                     "API",
                     "Entity Framework",
                     "DTO",
                     "Repository Pattern",
                     "HTML",
                     "CSS",
                  ],
                  image: "assets/images/projects/visitor_management",
                  live_demo: "https://visitor-management.netlify.app/",
                  status: "En cours",
               },
               inventory_management: {
                  title: "Gestion d'Inventaire",
                  description:
                     "Application qui permet de réaliser un inventaire simple et basique d'objets personnels.",
                  github_link: "",
                  technologies_used: [
                     "NET Core",
                     "Entity Framework",
                     "React",
                     "HTML",
                     "CSS",
                     "API",
                  ],
                  image: "assets/images/projects/inventory_management",
                  live_demo: "https://www.emilien-boinet.fr",
                  status: "À développer",
               },
               holiday_planner: {
                  title: "Planificateur de Vacances",
                  description:
                     "Le planificateur de vacances est une application qui permet de créer un itinéraire personnalisé jour par jour pour des vacances.",
                  github_link: "",
                  technologies_used: [
                     "NET Core",
                     "Angular",
                     "HTML",
                     "CSS",
                     "API",
                     "Entity Framework",
                  ],
                  image: "assets/images/projects/holiday_planner",
                  live_demo: "https://www.emilien-boinet.fr",
                  status: "À développer",
               },
            },
         },
         contact: {
            title: "Contact",
            sub_title: "Me Contacter",
            job_title: "Développeur Junior .Net",
            quote: "La programmation ne dépend pas de ce que vous savez, mais de ce que vous pouvez résoudre.",
            author: "Chris Pine",
            name: {
               placeholder: "Nom *",
               title: "Le nom",
            },
            email: {
               placeholder: "E-mail *",
               title: "L'e-mail",
            },
            subject: {
               placeholder: "Sujet",
               title: "Le sujet",
            },
            message: {
               placeholder: "Message *",
               title: "Le message",
            },
            submit: "Envoyer",
            alert_messages: {
               string: "doit être une chaîne de caractères",
               required: "est obligatoire",
               min: "doit comporter au moins {value} caractères",
               invalid: "est invalide",
               submited: "L'email a été envoyé !",
               sending_error:
                  "Oups, une erreur s'est produite, veuillez m'envoyer un message à emilien.boinet@gmail.com.",
            },
         },
         social_networks: {
            cv: {
               href: "./assets/documents/CV_Emilien_BOINET_Fr.pdf",
               icon: ["fas", "file-alt"],
            },
            github: {
               href: "https://github.com/Mimi-Git",
               icon: ["fab", "github-square"],
            },
            linkedin: {
               href: "https://fr.linkedin.com/in/emilien-boinet",
               icon: ["fab", "linkedin"],
            },
            email: {
               href: "mailto:emilien.boinet@gmail.com",
               icon: ["fas", "envelope"],
            },
         },
         footer: {
            made: "Site web créé à l'aide de ",
         },
      },
   },
};

i18n
   .use(initReactI18next)
   .use(LanguageDetector)
   .init({
      resources: languagesData,
      supportedLngs: ["fr", "en"],
      fallbackLng: "fr",
      detection: {
         order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
         caches: ["cookie", "localStorage"],
      },
   });

//i18n
//    .use(initReactI18next)
//    .use(LanguageDetector)
//    .use(HttpApi)
//    .init({
//        supportedLngs: ['fr', 'en'],
//        fallbackLng: "fr",
//        detection: {
//            order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
//            caches: ['cookie', 'localStorage'],
//        },
//        backend: {
//            loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
//            //loadPath: '/assets/locales/{{lng}}/translation.json',
//        },
//    });

export default i18n;
