import React from 'react';
import { Container, Row } from 'reactstrap';
import { SocialNetworks } from '../common/SocialNetworks';

export const Home = (props) => {
    const id = "home";
    const content = props.content;

    return (
        <header className="masthead" id={id}>
            <Container className="h-100">
                <Row className="h-100 align-items-center justify-content-center text-center">
                    <div className="align-self-center">
                        <button className="btn btn-primary btn-xl mb-4 js-scroll-trigger">{content.greeting}</button>
                        <div className="typewriter"><h1 className="text-white font-weight-bold">Emilien Boinet</h1></div>
                        <hr className="divider my-4" />
                        <p className="text-white-75 mb-4">{content.job_title}</p>
                        <SocialNetworks />
                    </div>
                </Row>
            </Container>
        </header>
    )
}