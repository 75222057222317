import React, { Component } from "react";
import {
   Collapse,
   Navbar,
   NavbarBrand,
   NavbarToggler,
   NavItem,
   NavLink,
   Dropdown,
   DropdownToggle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { LanguagesDropDownMenu } from "./LanguagesDropDownMenu";
import i18next from "i18next";

export class NavMenu extends Component {
   static displayName = NavMenu.name;

   constructor(props) {
      super(props);

      this.toggleNavbar = this.toggleNavbar.bind(this);
      this.toggleDropdown = this.toggleDropdown.bind(this);
      this.closeNavbar = this.closeNavbar.bind(this);
      this.languageChanged = this.languageChanged.bind(this);
      this.state = {
         collapsed: true,
         dropdownOpen: false,
         menu: this.props.content,
      };
   }

   toggleNavbar() {
      this.setState({
         collapsed: !this.state.collapsed,
      });
   }

   toggleDropdown() {
      this.setState({
         dropdownOpen: !this.state.dropdownOpen,
      });
   }

   languageChanged() {
      this.setState({
         menu: i18next.t("root:navmenu", { returnObjects: true }),
      });
   }

   componentDidMount() {
      window.addEventListener("scroll", this.handleScroll, true);
   }

   componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll, true);
      console.log(this.props.content);
   }

   handleScroll() {
      var mainNav = document.getElementById("mainNav");
      if (window.pageYOffset > 75) {
         mainNav.classList.add("navbar-scrolled");
      } else {
         mainNav.classList.remove("navbar-scrolled");
      }
   }

   closeNavbar() {
      if (this.state.collapsed !== true) {
         this.toggleNavbar();
      }
   }

   render() {
      return (
         <header>
            <Navbar
               expand="lg"
               light
               className="fixed-top py-3 px-4"
               id="mainNav"
            >
               <NavbarBrand tag={Link} to="/">
                  &lt;Mimi's Portfolio/&gt;
               </NavbarBrand>
               <NavbarToggler onClick={this.toggleNavbar} />
               <Collapse isOpen={!this.state.collapsed} navbar>
                  <ul className="navbar-nav ml-auto my-2 my-lg-0">
                     <NavLinks
                        menu={this.state.menu}
                        closeNavbar={this.closeNavbar}
                     />
                     <NavItem>
                        <Dropdown
                           isOpen={this.state.dropdownOpen}
                           toggle={this.toggleDropdown}
                        >
                           <DropdownToggle>
                              <FontAwesomeIcon icon={["fas", "globe"]} />
                           </DropdownToggle>
                           <LanguagesDropDownMenu
                              closeNavbar={this.closeNavbar}
                              languageChanged={this.languageChanged}
                           />
                        </Dropdown>
                     </NavItem>
                  </ul>
               </Collapse>
            </Navbar>
         </header>
      );
   }
}

const NavLinks = (props) => {
   var menu = Object.entries(props.menu);

   return menu.map(([key, value]) => (
      <NavItem key={key} className="align-self-lg-center">
         <NavLink onClick={props.closeNavbar} href={`#${key}`}>
            {value}
         </NavLink>
      </NavItem>
   ));
};
