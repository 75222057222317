import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TagCloud } from '../common/TagCloud';

export const Skills = (props) => {
    const id = "skills";
    const content = props.content;
    const types = Object.entries(content.types);
    const certificates = Object.entries(content.certificates);

    const TypesDisplay = types.map((type, index) => <TypeDisplay key={index} type={type[1]} />);
    const CertificatesDisplay = certificates.map((certificate, index) => <CertificateDisplay key={index} certificate={certificate[1]} />);

    return (
        <section className="page-section" id={id}>
            <Container>
                <h2 className="text-center mt-0">{content.title}</h2>
                <hr className="divider my-4" />
                <div>
                    <h4 className="h4 pt-4">{content.main_skills}</h4>
                    <hr className="mb-3" />
                    <Row>{TypesDisplay}</Row>
                </div>
                <div>
                    <h4 className="h4 pt-4">{content.certificate}</h4>
                    <hr className="mb-3" />
                    <Row>{CertificatesDisplay}</Row>
                </div>
            </Container>
        </section>
    )
}

const TypeDisplay = (props) => {
    var type = props.type;

    return (
        <Col lg="4" md="6" className="text-center d-flex mx-auto" data-aos="fade-right">
            <Card className="mb-4">
                <CardHeader className="pt-3">
                    <FontAwesomeIcon icon={["fas", type.logo]} size="2x" className="text-primary mb-3" />
                    <h3 className="h4">{type.title}</h3>
                </CardHeader>
                <CardBody className="p-3">
                    <TagCloud List={type.skills} />
                </CardBody>
            </Card>
        </Col>
    );
}

const CertificateDisplay = (props) => {
    var certificate = props.certificate;
    var imgLink = `assets/images/skills/${certificate.abb}`;

    return (
        <Col lg="6" className="mb-4" data-aos="fade-right">
            <Row className="certificate pt-0 px-3">
                <Col xl="3" lg="12" md="3" className="certificate-icon p-3 text-center">
                    <picture>
                        <source srcSet={imgLink + ".webp"} type="image/webp" />
                        <img src={imgLink + ".jpg"} alt={certificate.abb} />
                    </picture>
                </Col>
                <Col xl="9" lg="12" md="9" className="certificate-content bg-primary p-3">
                    <h6 className="h5">{certificate.title}</h6>
                    <h6 className="h6"><b>{certificate.level}</b></h6>
                    <div>{certificate.location}</div>
                    <div className="small text-right mt-1">{certificate.date}</div>
                </Col>
            </Row>
        </Col>
    );
}