
import React, { Suspense } from 'react';
import './i18n';
import './custom.scss';
import "aos/dist/aos.css";
//import 'animate.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { Spinner } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const loadingMarkup = (
    <div className="py-4 text-center">
        <Spinner color="primary" />
    </div>
)

const MyAppTranslated = withTranslation("root")(App);

ReactDOM.render(
    <Suspense fallback={loadingMarkup}>
        <BrowserRouter basename={baseUrl}>
            <MyAppTranslated />
        </BrowserRouter>
    </Suspense>,
    rootElement);

registerServiceWorker();