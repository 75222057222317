import React from "react";
import { TagCloud } from "../common/TagCloud";
import { Container, Row, Col, Card, CardFooter, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Projects = (props) => {
   const id = "projects";
   const content = props.content;
   const projects = Object.entries(content.projects);

   return (
      <section className="page-section bg-primary" id={id}>
         <Container>
            <h2 className="text-center mt-0">{content.title}</h2>
            <hr className="divider my-4 light" />
            <Row>
               {projects.map((projet, index) => (
                  <ProjectsDisplay key={index} projet={projet[1]} />
               ))}
            </Row>
         </Container>
      </section>
   );
};

const ProjectsDisplay = (props) => {
   var projet = props.projet;
   return (
      <Col
         lg="4"
         md="6"
         className="text-center d-flex mx-auto"
         data-aos="fade-up"
      >
         <Card className="projects-item mb-4 border-0">
            <div className="projects-link">
               <div className="projects-hover">
                  <ProjectsLinks projet={projet} />
               </div>
               <picture>
                  <source srcSet={projet.image + ".webp"} type="image/webp" />
                  <img
                     className="img-fluid"
                     src={projet.image + ".jpg"}
                     alt="project"
                  />
               </picture>
            </div>
            <CardBody className="projects-caption">
               <div className="projects-caption-heading">{projet.title}</div>
               <div className="projects-caption-subheading pb-1">
                  {projet.description}
               </div>
            </CardBody>
            <CardFooter>
               <TagCloud List={projet.technologies_used} />
               <div className="text-primary my-2">
                  {projet.status.toUpperCase() === "EN COURS" ||
                  projet.status.toUpperCase() === "IN PROGRESS"
                     ? ""
                     : projet.status.toUpperCase()}
               </div>
               <ProjectsLinks projet={projet} />
            </CardFooter>
         </Card>
      </Col>
   );
};

function ProjectsLinks({ projet }) {
   if (
      projet.live_demo === "https://www.emilien-boinet.fr" ||
      projet.live_demo === ""
   )
      return <></>;

   return (
      <div className="projects-hover-content">
         <a href={projet.live_demo} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon
               icon={["fas", "globe"]}
               size="2x"
               className="mr-2"
            />
         </a>
         <a href={projet.github_link} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon
               icon={["fab", "github"]}
               size="2x"
               className="ml-2"
            />
         </a>
      </div>
   );
}
