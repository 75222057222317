import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { ContactForm } from './ContactForm';
import { ContactCard } from './ContactCard';

export const Contact = (props) => {
    const id = "contact";
    const content = props.content;

    return (
        <section className="page-section" id={id}>
            <Container>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <h2 className="mt-0">{content.title}</h2>
                        <hr className="divider my-4" />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" className="mb-4" data-aos="fade-right">
                        <ContactCard Info={content} />
                    </Col>
                    <Col lg="6" className="mb-4" data-aos="fade-right">
                        <ContactForm Info={content} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}