import React from 'react';

export const TagCloud = (props) => {
    const tagList = props.List;

    return (
        <div className="tagcloud">
            {tagList.map((list, index) => <span key={index} className="m-1">{list}</span>)}
        </div>    
    )
} 