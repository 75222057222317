import React from 'react';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Footer = (props) => {
    return (
        <footer className="bg-light py-5">
            <Container>
                <div className="small text-center text-muted">
                    Copyright © {new Date().getFullYear()} - Emilien Boinet
                </div>
                <div className="small text-center text-muted mt-2">
                    {props.content.made} <a href="https://reactjs.org/" >React<FontAwesomeIcon icon={["fab", "react"] } className="ml-1"/></a>
                </div>
            </Container>
        </footer>
    )
}