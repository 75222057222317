import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";

export const SocialNetworks = () => {
    const { t } = useTranslation("root");
    var sn = Object.entries(t('social_networks', { returnObjects: true }));
    return (
        <ul className="p-0 m-0 d-flex justify-content-around" style={{ "listStyle": "none" }}>
            {
                sn.map((item, index) =>
                    <li key={index}>
                        <a href={item[1].href} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={item[1].icon} size="2x" />
                        </a>
                    </li>
                )
            }
        </ul>
    )
};