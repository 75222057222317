import React, { Component } from 'react'; 
import AOS from "aos";
import { NavMenu } from './components/navmenu/NavMenu';
import { Home } from './components/home/Home';
import { About } from './components/about/About';
import { Skills } from './components/skills/Skills';
import { Experiences } from './components/experiences/Experiences';
import { Education } from './components/education/Education';
import { Projects } from './components/projects/Projects';
import { Contact } from './components/contact/Contact';
import ScrollToTop from './components/common/ScrollToTop';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Footer } from './components/footer/Footer';


library.add(fab, fas);

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        AOS.init({
            duration: 1800
        });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <NavMenu content={t('navmenu', { returnObjects: true })} />
                <Home content={t('home', { returnObjects: true })} />
                <About content={t('about', { returnObjects: true })} />
                <Skills content={t('skills', { returnObjects: true })} />
                <Experiences content={t('experiences', { returnObjects: true })} />
                <Education content={t('education', { returnObjects: true })} />
                <Projects content={t('projects', { returnObjects: true })} />
                <Contact content={t('contact', { returnObjects: true })} />
                <Footer content={t('footer', { returnObjects: true })} />
                <ScrollToTop />
            </>
        );
    }
}

